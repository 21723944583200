.ck-table {
    background-color: transparent !important;
    box-shadow: none !important;
    display: flex;
    justify-content: center;

    table {
        // table-layout: auto;
        // width: 100%;

        td.shrink,
        th.shrink {
            white-space: nowrap;
        }

        th.grow,
        td.grow {
            width: 99%;
        }
    }

    .ck-table-head {
        display: table-header-group;
    }

    .MuiTableCell-head {
        border: none;
        background-color: transparent;
        color: #1a73e8;
    }

    .MuiTableCell-root {
        border: none;
    }
}
