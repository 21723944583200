.overlay-container {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Overlay color */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: inherit;
}

.overlay-container:hover .overlay {
    opacity: 1;
    cursor: pointer;
}

.ck-image-upload {
    .btn--upload {
        line-height: 1rem !important;
        padding: 1rem 2rem;

    }
}
