.ck-chat-box {
    width: 100%;
    height: 100%;

    .ck-chat-center-container {
        display: flex;
        flex: 1;
        gap: 1.5vw;
        flex-direction: column;
        height: 100%;
        margin: auto 0;
        padding: 0 1vw;

        @media (max-width: 820px) {
            height: 80%;
        }
    }

    .ck-chat-message-content {
        display: flex;
        font-size: 0.8em;
        font-weight: 300;
        padding: 0.8em 1em;
        max-width: 80%;
        width: fit-content;
        height: fit-content;
    }

    .ck-chat-message-container {
        display: flex;
        gap: 10px;
        color: #bbb;
        font-size: 1rem;

        &.sent-message {
            flex-direction: row-reverse;

            .ck-chat-message-content {
                text-align: end;
                background: #1a73e8;
                border: 1px solid rgba(0, 0, 0, 0.1);
                color: #fff;
                box-shadow: rgba(0, 0, 0, 0.15) 2px 3px 15px;
                border-radius: 8px 0 8px 8px;
            }
        }

        &.received-message {
            flex-direction: row;

            .ck-chat-message-content {
                border: none;
                background: #fff;
                color: #000;
                box-shadow: rgba(32, 112, 198, 0.4) 2px 3px 15px;
                border-radius: 0 8px 8px 8px;
            }
        }
    }

    .ck-chat-conversation-container {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        flex: 1;
        padding: 20px 0;
        overflow: auto;
    }

    .ck-chat-box-header {
        display: flex;
        align-items: center;
        gap: 1.1em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        height: 4.2em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        & img {
            height: 100%;
            min-height: 1rem;
            border-radius: 0.7em;
        }

        & h2 {
            font-size: 16px;
            font-weight: 700;
            margin: 0px;
        }
    }

    .ck-chat-input-container {
        padding: 0.5vw 0;
        display: flex;
        align-items: center;
        height: 10%;
        min-height: 75px;

        border-top: 1px solid rgba(0, 0, 0, 0.08);

        input {
            flex: 1;
            height: 100%;
            width: 100%;
            border: none !important;
        }

        fieldset {
            border: none !important;
        }
    }

    .ck-chat-btn {
        border: 0px !important;
        box-shadow: none !important;
    }

    .ck-chat-bot-message {
        width: fit-content;
        margin: 0 auto;
        padding: 0.85em 1.7em;
        font-size: 0.7em;
        text-align: center;
        border-radius: 2em;
        background: rgba(0, 0, 0, 0.05);
    }

    .ck-chat-user-bubble {
        display: flex;
        position: relative;
        flex-direction: column;
        font-size: 0.7rem;
        height: 100%;
        width: 32px;
        margin-top: -0.5rem;

        img {
            width: 100%;
            height: 32px;
            aspect-ratio: 1;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .ck-chat-box-container {
        --vertical-padding: 3vh;

        display: flex;
        gap: 2vw;
        // width: 100%;
        height: 100%;
        justify-content: space-between;
        background: transparent;
        border-radius: 0.75rem;
        box-shadow:
            rgba(0, 0, 0, 0.25) 0px 54px 55px,
            rgba(0, 0, 0, 0.12) 0px -12px 30px,
            rgba(0, 0, 0, 0.12) 0px 4px 6px,
            rgba(0, 0, 0, 0.17) 0px 12px 13px,
            rgba(0, 0, 0, 0.09) 0px -3px 5px;

        @media (max-width: 820px) {
            position: relative;
            width: 100%;
            height: 100%;
            flex-direction: column-reverse;
            font-size: 0.85rem;
            gap: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .ck-chat-box {
        height: 100%;
    }
}
