.ck-form {
    @media screen and (max-width: 768px) {
        .ck-form-subtitle {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            width: 100%;
        }
    }

    @media screen and (min-width: 768px) {
        .ck-form-title {
        }
    }
}
