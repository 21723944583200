.event-list-logo {
    object-fit: contain;
    width: 50px;
    height: 50px;
}

.ck-event-list-item:hover {
    transform: scale(1.05);
    cursor: pointer;
    z-index: 100;
    position: relative;
}
.ck-event-list-item {
    transition: all 0.2s ease-in-out;
}
