.dark-mode-image {
  mix-blend-mode: normal !important;
  filter: brightness(0.9) contrast(1.1);
}

[data-darkreader-mode="dynamic"] {
  // General image styles
  img:not(.MuiAvatar-img):not(.ck-location-compact-badge img):not(.ck-organisation-compact-badge img):not(.ck-workshop-compact-badge img) {
    @extend .dark-mode-image;
  }

  // Badge specific styles with higher specificity
  .ck-organisation-compact-badge,
  .ck-location-compact-badge,
  .ck-workshop-compact-badge {
    background-color: white !important;

    .MuiAvatar-root {
      background-color: white !important;
      background-image: none !important;
    }

    img,
    .MuiAvatar-img {
      mix-blend-mode: multiply !important;
      filter: none !important;
      background-color: transparent !important;
      -webkit-filter: none !important;
      backdrop-filter: none !important;
      -webkit-backdrop-filter: none !important;
      isolation: isolate !important;
      /* Override DarkReader specific styles */
      --darkreader-inline-bgcolor: transparent !important;
      --darkreader-inline-bgimage: none !important;
      --darkreader-inline-filter: none !important;
    }
  }

  // Other logo styles
  .event-card-logo,
  .event-row-logo,
  .event-list-logo {
    mix-blend-mode: multiply !important;
    background-color: white;
    border-radius: inherit;
  }

  /* Override the global dark mode image styles */
  .ck-organisation-compact-badge img,
  .ck-location-compact-badge img,
  .ck-workshop-compact-badge img,
  .ck-organisation-compact-badge .MuiAvatar-img,
  .ck-location-compact-badge .MuiAvatar-img,
  .ck-workshop-compact-badge .MuiAvatar-img {
    mix-blend-mode: normal !important;
    filter: none !important;
    -webkit-filter: none !important;
    brightness: initial !important;
    contrast: initial !important;
  }
}

/* Additional specificity if needed */
html[data-darkreader-mode=dynamic] body [data-darkreader-mode=dynamic] {
  .ck-organisation-compact-badge img,
  .ck-location-compact-badge img,
  .ck-workshop-compact-badge img,
  .ck-organisation-compact-badge .MuiAvatar-img,
  .ck-location-compact-badge .MuiAvatar-img,
  .ck-workshop-compact-badge .MuiAvatar-img {
    mix-blend-mode: normal !important;
    filter: none !important;
    -webkit-filter: none !important;
    brightness: initial !important;
    contrast: initial !important;
  }
} 