.ck-page-badge-container img {
    height: 100% !important;
}

.ck-page-content {
    min-height: 60vh;
}

.ck-page-title {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .ck-page-title {
        font-size: 1.75rem !important;
        display: flex;
        flex-direction: column;
    }
}
