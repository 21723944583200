.ck-elevated-user-card {
    background-color: #ffffff80 !important;

    .ck-elevated-user-name {
        font-size: 0.9rem;
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .ck-elevated-user-card-header {
        width: 100%;
    }
    .ck-elevated-user-card-header:hover {
        cursor: pointer;
    }
}
