.ck-lineup-toggle:hover {
    cursor: pointer;
}

.ck-lineup-toggle {
    width: 50px;
}

.ck-lineup-user-name {
    font-size: 0.9rem;
    margin: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ck-table-head {
    font-size: 1rem;
    font-weight: 500;
    color: #1a73e8;
}
