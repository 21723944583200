.ck-my-calendar {
    margin: 0px !important;
    width: 100% !important;
    max-height: 390px !important;
    height: 390px !important;

    .MuiPickersCalendarHeader-label {
        font-size: 1rem;
        color: #344767aa;
    }

    .Mui-selected {
        background-color: #1a73e8aa !important;
    }

    .ck-event-day {
        background-color: #1a73e833;
    }

    .ck-calendar-day {
        margin: 0.3rem;
    }

    .MuiDayCalendar-weekDayLabel {
        margin: 0.3rem;
        color: #1a73e8;
    }

    .MuiPickersSlideTransition-root {
        overflow: hidden;
        height: 300px;
    }
}

.ck-calendar-container {
    width: 100%;
}
