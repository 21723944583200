.ck-modal-box {
    position: absolute !important;
    top: 50%;
    left: 50%;
    width: 700px;
    transform: translate(-50%, -50%);
    // width: 400,
    // background-color: #fff !important;
    border-radius: 0.65rem;
    box-shadow: 24;
    margin-top: 0px !important;
    max-width: 100%;
    margin: 0px !important;
    max-height: 70vh;
}

.ck-modal-close-btn {
    background-color: transparent !important;
    position: absolute !important;
    top: 3px;
    right: 3px;
    box-shadow: none !important;
}

.ck-modal-box:focus-visible {
    outline: none;
}

@media screen and (max-width: 768px) {
    .ck-modal-box {
        padding: 1rem !important;
        width: 100%;
        max-height: 90vh;
        margin-top: 0px !important;
        height: 100vh;
    }

    .ck-modal-badge {
        display: none !important;
    }
}
