.ck-coffee-banner {
    width: 100%;
    display: none;
    text-align: center !important;
    position: fixed;
    bottom: 0px;
    // background: linear-gradient(195deg, #EBEFF4, #CED4DA);
    background-color: #ffffffee;
    padding: 5px 0px;
    font-size: 1rem;
}
@media screen and (max-width: 768px) {
    .ck-coffee-banner {
        font-size: 0.8rem !important;
    }
}
