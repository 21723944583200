//Components
@use 'styles/components/CKImagePicker.scss';
@use 'styles/components/CKDarkMode.scss';

body {
    overflow-x: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    /* box-shadow: 0rem 0.3125rem 0.625rem 0rem rgba(0, 0, 0, 0.12);
  background: linear-gradient(285deg, #42424a, #191919) !important; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.map-container {
    height: 100%;
    width: 100%;
    border-radius: 0.75rem;
}

@media screen and (max-width: 768px) {
    .navbar-container {
        padding: 0px !important;
        max-width: 100% !important;
        margin: 0px !important;
    }

    .navbar-box {
        border-radius: 0px !important;
    }
}
