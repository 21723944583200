.ck-address-btn:hover {
    cursor: pointer;
}

.ck-address-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ck-workshop-compact-container {
    transition: all 0.2s ease-in-out;
    text-align: center !important;

    &:hover {
        transform: scale(1.05);
        cursor: pointer;
        z-index: 100;
        position: relative;
    }

    .ck-workshop-compact-banner {
        height: 100px;
        object-fit: contain;
        margin: 0px;
    }

    .ck-workshop-compact-badge {
        margin: auto;
        margin-top: -30px;
        background-color: #ffffffcc;

        img {
            height: 100% !important;
        }
    }
}
