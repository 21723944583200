.ck-settings-button {
    // border-color: transparent !important;
    // padding: 0px !important;
}

.ck-settings-dropdown-menu {
    .MuiListItemText-primary {
        font-size: 1rem !important;
    }
}

.ck-hidden-settings-item {
    position: absolute;
    top: -10000px;
}
