.ck-dark-mode-toggle {
    background-color: transparent !important;
    // width: 20px !important;
    border: none !important;
    box-shadow: none !important;
}

.ck-dark-mode-toggle:hover {
    box-shadow: none;
}
.ck-dark-mode-toggle:focus {
    box-shadow: none !important;
    border: none;
}
