.ck-floating-container {
    position: fixed !important;
    padding: 3rem 0;
    bottom: 0;
    width: 100%;
    transition: opacity 0.3s ease, max-height 3s ease;
}

.ck-floating-container-dropback {
    background-color: #ffffffaa;
}