.event-row {
    .event-row-logo {
        height: 100px;
        max-width: 100%;
        object-fit: contain;
        mix-blend-mode: multiply;
    }

    .event-row-description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        /* number of lines to show */
        line-clamp: 4;
        -webkit-box-orient: vertical;
        height: 96px;
    }

    &:hover {
        transform: scale(1.05);
        cursor: pointer;
        z-index: 100;
        position: relative;
    }
    border-radius: 1rem;
}