.ck-chat-page {
    .ck-chat-room-card {
        transition: all 0.2s ease-in-out;

        &:hover {
            transform: scale(1.05);
            cursor: pointer;
            z-index: 100;
        }

        .chat-room-title {
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 1rem;
        }

        .chat-room-label {
            font-style: italic;
            color: #344767aa;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .unread-badge-container {
            display: flex;
            height: 30px;
            justify-content: center;
            align-items: center;
        }

        .last-msg-time {
            font-size: 0.8rem;
            font-style: italic;
            color: #344767aa;
            text-align: left;
        }

        .unread-msg-badge {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            color: white;
            font-size: 0.8rem;
        }

        .chat-room-button {
            border: 0px !important;
            display: flex;
            flex-direction: column;
        }

        @media screen and (max-width: 768px) {
            .chat-room-title {
                font-size: 0.8rem;
            }

            .unread-badge-container {
                height: 25px;
            }

            .unread-msg-badge {
                width: 20px;
                height: 20px;
                font-size: 0.7rem;
            }

            .chat-room-label {
                font-size: 0.7rem;
            }

            .last-msg-time {
                font-size: 0.7rem;
            }
        }
    }

//     display: flex;
//     flex-flow: column;
//     height: 95vh;

//     .ck-chat-hero {
//         flex: 0 1 auto;
//     }

//     // .ck-chat-page-content {
//     //     display: flex;

//     // }

//     .ck-chat-page-body {
//         flex: 1 1 auto;
//         display: flex;
//         justify-content: center;
//     }

//     .ck-chat-container {
//         display: flex;
//         flex-flow: column;
//     }

//     .ck-chat-body-container {
//         height: 100%;
//         display: flex;
//     }

//     .ck-chat-overview {
//         height: 100%;
//         width: 500px;
//         display: flex;
//         justify-content: center;
//     }

//     .ck-chat-detail {
//         width: 100%;
//     }

//     @media screen and (max-width: 768px) {
//         height: 85vh;

//         .ck-chat-overview {
//             width: 100%;
//             min-width: 0;
//         }

//         .ck-chat-page-body {
//             padding: 0px !important;
//         }
//     }
// }
}
.ck-chat-modal {
    width: 100%;

    .ck-modal-box {
        height: 100vh;
        background-color: white !important;
        width: 100vw;
        padding: 0px !important;
    }
}
