.ck-location-map-container {
    width: 100%;
    max-width: 1920px;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    .ck-location-map-container {
        padding: 1rem;
        aspect-ratio: 16/9;
    }
}

@media screen and (max-width: 768px) {
    .ck-location-map-container {
        height: 70vh;
        margin: 0px -3rem;
        width: 100vw;
    }
}

.ck-location-marker {
    background-color: white;
    width: 40px;
    height: 40px;
    padding: 2px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.2);
        cursor: pointer;
        z-index: 100;
    }

    img {
        width: 100%;
        border-radius: 50%;
    }
}
