.ck-organisation-compact-container {
    transition: all 0.2s ease-in-out;
    text-align: center !important;

    &:hover {
        transform: scale(1.05);
        cursor: pointer;
        z-index: 100;
        position: relative;
    }

    .ck-organisation-compact-banner {
        height: 100px;
        object-fit: contain;
        margin: 0px;
    }

    .ck-organisation-compact-badge {
        margin: auto;
        margin-top: -30px;
        background-color: #ffffffcc;

        img {
            height: 100% !important;
        }
    }
}
