.ck-checkbox {
    margin-left: 0px;

    .MuiCheckbox-root {
        padding: 5px;
    }

    .MuiFormControlLabel-label {
        font-weight: normal !important;
        font-size: 0.75rem !important;
    }
}
