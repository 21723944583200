.ck-settings-page {
    display: flex;
    flex-flow: column;
    // height: 95vh;

    @media screen and (max-width: 768px) {
        // height: 85vh;
    }

    .ck-settings-hero {
        // flex: 0 1 auto;
    }

    .ck-settings-page-content {
        flex: 1 1 auto;
        display: flex;
    }

    .ck-settings-page-body {
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
    }

    .ck-settings-container {
        height: 100%;
        display: flex;
        flex-flow: column;
    }

    .ck-settings-body-container {
        height: 100%;
        display: flex;
    }

    .ck-settings-menu {
        // background-color: white;
        // border-radius: 0.4rem !important;
        // box-shadow: 1px 0px 20px 0px rgba(0, 0, 0, 0.1);
        flex-grow: 1;
        height: 60vh;
        min-height: 600px;
        border-right: 1px solid rgb(197, 197, 197);
    }

    .Mui-selected {
        background-color: #49a3f159 !important;
    }

    .ck-settings-menu-option {
        border-radius: 0.4rem !important;

        &:hover {
            background-color: aliceblue;
        }
    }

    .ck-settings-option-box {
        // box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
    }

    .ck-settings-modal {
        width: 100%;

        .ck-modal-box {
            height: 100vh;
            // background-color: white !important;
            width: 100vw;
            padding: 0px !important;
        }
    }
}
