.ck-artist-card {
    h5 {
        font-size: 1rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    h6 {
        font-size: 0.7rem;
    }

    .ck-artist-description {
        margin-top: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        font-size: 0.8rem;
    }

    .ck-artist-picture {
        aspect-ratio: 1/1;
        object-fit: cover;
    }

    height: 170px;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.05);
        cursor: pointer;
        z-index: 100;
        position: relative;
    }

    .ck-artist-read-more {
        text-align: end;
        position: absolute;
        bottom: 0px;
        right: 0px;
    }
}
