.event-lineup-avatar {
    &:hover {
        transform: scale(1.2);
        cursor: pointer;
        z-index: 100;
        position: relative;
    }

    img {
        height: 100% !important;
    }
}
