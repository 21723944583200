.ck-spot-edit-card {
    input {
        text-align: center;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    .MuiInput-root {
        width: 50px;
    }

    .spot-delete-btn {
        color: #f44335;
    }

    .ck-spot-edit-input-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .MuiTypography-caption {
        color: #7b809a;
    }

    .event-spot-card-header {
        display: flex;
    }

    .event-spot-card-header:hover {
        cursor: pointer;
    }

    .spot-collapse-btn {
        top: 0px;
        right: 0px;
        position: absolute;
    }

    .spot-title {
        font-size: 0.75rem;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        margin-bottom: 0.5rem;
    }
}
